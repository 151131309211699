<template>
  <div>
    <b-modal :no-close-on-backdrop="true" id="add-commission-modal" ref="add-commission-modal" centered hide-footer>
      <validation-observer ref="addStudentForm">
        <b-form class="auth-register-form mt-2">
          <div>
            <b-form-group label="Name" label-for="commission_name">
              <validation-provider #default="{ errors }" name="name" rules="required">
                <b-form-input id="commission_name" name="commission_name" :state="errors.length > 0 ? false : null"
                  placeholder="Name" v-model="commission_form.name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Currency" label-for="currency">
              <validation-provider #default="{ errors }" name="currency" rules="required">
                <v-select id="currency" v-model="commission_form.currency" clearable :options="currencyCodes.data"
                  label="code" :reduce="(item) => item.code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Amount" label-for="amount">
              <validation-provider #default="{ errors }" name="amount" rules="required">
                <b-form-input id="amount" :state="errors.length > 0 ? false : null" name="amount" type="number"
                  v-model="commission_form.amount" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Percent" label-for="percent">
              <validation-provider #default="{ errors }" name="percent" rules="required">
                <b-form-input id="percent" :state="errors.length > 0 ? false : null" name="percent" type="number"
                  v-model="commission_form.percent" min="1" max="100" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Document Url" label-for="document_url">
              <validation-provider #default="{ errors }" name="document_url" rules="required">
                <b-form-input id="document_url" name="document_url" :state="errors.length > 0 ? false : null"
                  placeholder="Document Url" v-model="commission_form.document_url" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Notes" label-for="notes">
              <validation-provider #default="{ errors }" name="notes" rules="required">
                <b-form-textarea id="notes" v-model="commission_form.notes" placeholder="Notes" rows="3"
                  max-rows="6"></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="text-right">
            <b-button @click="closeModal" variant="outline-danger mr-2">
              Close
            </b-button>
            <b-button variant="outline-primary" type="submit" @click.prevent="addCommission">
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-card no-body class="p-2">
      <h5 class="mb-1">Filters</h5>
      <!-- Table Filters -->
      <b-row cols="10">
        <b-col md="7">
          <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
            @input="onSearch" />
        </b-col>
        <b-col md="3">
          <!-- <v-select
            v-model="filters.selectedCountry"
            :reduce="(country) => country.country_id"
            label="country_name"
            :options="this.$store.state.home.Countries"
            placeholder="Country"
            @input="filterByCountry"
          ></v-select> -->
        </b-col>

        <b-col md="2" class="
            d-flex
            align-items-end
            justify-content-center
            mb-1 mb-md-0
            pr-2
          ">
          <button class="btn btn-primary w-100" @click="resetFilters">
            Reset
          </button>
        </b-col>

        <!-- Per Page -->
      </b-row>
    </b-card>
    <b-card class="mb-0">
      <!-- Student List -->
      <b-table id="UniListTable" ref="refStudentsListTable" class="position-relative" :items="fullUniList" responsive
        :fields="tableColumns" primary-key="university_id" show-empty empty-text="No matching records found"
        :per-page="pagination.perPage" :current-page="pagination.currentPage" @row-clicked="row_clicked" selectable>
        <template #cell(addCommision)="data">
          <div class="">
            <b-button class="bg-white bg-transparent border-0 p-0 justify-content-center align-items-center d-flex"
              variant="light" v-b-modal.add-commission-modal @click.prevent="row_clicked(data.item)">
              <feather-icon icon="ListIcon" size="18" /></b-button>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">
              {{ university_list.length }} entries
            </span>
          </b-col>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination aria-controls="UniListTable" v-model="pagination.currentPage"
              :total-rows="university_list.length" :per-page="pagination.perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormTextarea,
  BFormSelectOption,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import AccountsServices from "@/apiServices/AccountsServices";
import currencyCodes from "currency-codes";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
  },
  computed: {},
  watch: {},
  data() {
    return {
      fullStudentList: [],
      fullUniList: [],
      university_list: [],
      commission_form: {
        name: "",
        currency: "INR",
        amount: "",
        percent: null,
        document_url: "",
        notes: "",
        university_id: null,
      },
      currencyCodes,
      tableColumns: [
        { key: "university_id", label: "Id", sortable: false },
        { key: "university_name", label: "Name", sortable: false },
        // { key: "country_name", label: "Country", sortable: false },
        { key: "addCommision", label: "Commission", sortable: false },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      formErrors: {},

      filters: {
        search: "",
        selectedCountry: null,
      },
    };
  },
  methods: {
    row_clicked(e) {
      this.$router.push({
        name: "Commission Details",
        params: { university_id: e.university_id },
      });
    },
    async getUniList() {
      const response = await AccountsServices.getAllUniversities();
      this.university_list = response.data.data;
      this.fullUniList = response.data.data;
    },
    addUniversityId(id) {
      console.log(id);
      this.commission_form.university_id = id;
    },

    filterByCountry() {
      if (this.filters.selectedCountry) {
        console.log(this.filters.selectedCountry);
        this.fullUniList = this.university_list.filter(
          (uni) => uni.country_id === this.filters.selectedCountry
        );
      } else {
        this.fullUniList = this.university_list;
      }
      this.onSearch(); // Apply search filter after country filter
    },

    onSearch() {
      let filteredList = this.fullUniList.filter((uni) => {
        let university_name = uni.university_name;
        let text = this.filters.search;
        let text_search = true;

        if (text) {
          text_search = university_name
            .toLowerCase()
            .includes(text.toLowerCase());
        }

        return text_search;
      });

      this.fullUniList = filteredList;
    },
    async addCommission() {
      this.formErrors = {}; // Clear previous errors
      const { name, currency, amount } = this.commission_form;
      if (!name) this.formErrors.name = "Name is required.";
      if (!currency) this.formErrors.currency = "Currency is required.";
      if (!amount) this.formErrors.amount = "Amount is required.";

      if (Object.keys(this.formErrors).length > 0) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Validation Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: Object.values(this.formErrors).join(" & "),
          },
        });
        return;
      }
      const response = await AccountsServices.saveCommission(
        this.commission_form
      );
      if (response.data.status) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Commission Added",
            icon: "Check",
            variant: "success",
            text: response.data.message,
          },
        });
        setTimeout(() => {
          this.closeModal();
        }, 2000);
      }
    },
    closeModal() {
      this.$refs["add-commission-modal"].hide();
      this.commission_form = {
        name: "",
        currency: "INR",
        amount: "",
        document_url: "",
        notes: "",
      };
      this.formErrors = {};
    },

    resetFilters() {
      this.filters = {
        search: "",
      };

      this.getUniList();
    },
  },
  beforeMount() {
    this.$store.dispatch("home/onGetCountries");
    this.getUniList();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
